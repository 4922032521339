import React from 'react';
import './why.css';

const Door = () => {
    return (
        <div className='container'>
              <div style={{
            color:"#1FBA4A",
            fontFamily: "Inter",
            fontSize: "20px",
            fontWeight: 600,
            textAlign: "center",
            backgroundColor: "#FFFFFFD6",
            display: "center",
            padding: "30px",
            
            

        }}>
            <div className='conatainer why'>
                <h1>Why Go fuel?</h1>
            </div>
            
        </div>
        </div>
      
    );
}

export default Door;
